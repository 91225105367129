import { defineStore } from 'pinia'

export const useCathodeStore = defineStore('cathode', {
  state: () => ({
    scaleFontSizeY: null,
    scaleFontSizeX: null,
    scaleTitleFontSize: null,
    scaleTextColor: '#ffffff',
    baseScaleTextColor: '#ffffff',
    selectedChartIdDownloadToImage: null,
  }),
  actions: {
    setScaleTitleFontSize(size?: number) {
      this.scaleTitleFontSize = size
    },
    setScaleFontSizeY(size?: number) {
      this.scaleFontSizeY = size
    },
    setScaleFontSizeX(size?: number) {
      this.scaleFontSizeX = size
    },
    setScaleTextColor(color) {
      this.scaleTextColor = color
    },
    setSelectedChartIdDownloadToImage(chartId) {
      this.selectedChartIdDownloadToImage = chartId
    },
  },
})
